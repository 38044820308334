<template>
  <div class="text-center appsumo-form">
    <v-row>
      <v-col class="pb-6" cols="12">
        <img
          src="/media/logos/testapp.io_love_appsumo.svg"
          class="mx-auto max-h-70px"
          alt
        />
      </v-col>
      <v-col cols="12" class="text-center mb-2 font-weight-bold font-size-h2">
        👋 Hello Sumo-lings
      </v-col>
      <v-col class="font-size-h3" cols="12">
        <div class="mb-2">
          💪 You are one step closer to experience the best app distribution!
        </div>
        <div>
          Let's create an account for your instant to access to your special
          plan 🎉
        </div>
      </v-col>
    </v-row>
    <!--  -->
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-card tile flat class="transparent pb-15 mt-15">
          <signup-form
            from-appsumo
            @error="setErrorMessage"
            default-signup
          ></signup-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import SignupForm from "@/view/components/User/SignupForm";

export default {
  components: { SignupForm },
  mixins: [veeValidate],
  name: "signup",
  data() {
    return {
      errorMessage: "",
    };
  },
  methods: {
    setErrorMessage(message) {
      this.errorMessage = message;
    },
  },
};
</script>
<style scoped>
.appsumo-form {
  width: 100%;
}
</style>
